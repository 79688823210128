import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

import './Layout.scss'

let slimFooter = false

const Layout = ({ children, location }) => {
												const { pathname } = location

												if (pathname.indexOf('about') > -1) {
													slimFooter = true
												}

												return (
													<React.Fragment>
														{!pathname.includes('storyboard') && <Header {...location} />}

														{children}
														{!pathname.includes('storyboard') && <Footer slimFooter={slimFooter} />}
													</React.Fragment>
												)
											}

export default Layout
