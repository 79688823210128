exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-packages-js": () => import("./../../../src/pages/packages.js" /* webpackChunkName: "component---src-pages-packages-js" */),
  "component---src-pages-storyboards-js": () => import("./../../../src/pages/storyboards.js" /* webpackChunkName: "component---src-pages-storyboards-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-story-board-jsx": () => import("./../../../src/templates/StoryBoard.jsx" /* webpackChunkName: "component---src-templates-story-board-jsx" */)
}

